import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image, Structure, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Ремонт техники Эксперт
			</title>
			<meta name={"description"} content={"Ваш надежный партнер по ремонту техники"} />
			<meta property={"og:title"} content={"Ремонт техники Эксперт"} />
			<meta property={"og:description"} content={"Ваш надежный партнер по ремонту техники"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/2694900.jpg?v=2024-06-13T08:27:07.214Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/2694900.jpg?v=2024-06-13T08:27:07.214Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/2694900.jpg?v=2024-06-13T08:27:07.214Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/2694900.jpg?v=2024-06-13T08:27:07.214Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/2694900.jpg?v=2024-06-13T08:27:07.214Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/2694900.jpg?v=2024-06-13T08:27:07.214Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/2694900.jpg?v=2024-06-13T08:27:07.214Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Header>
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/10_a35.jpg?v=2024-06-13T08:27:07.204Z) 70%/cover scroll"
			padding="120px 0 120px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Stack>
				<StackItem width="75%" lg-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
						Мы стремимся обеспечить вас самым высоким качеством обслуживания, быстротой и надежностью.
					</Text>
					<Text as="h1" font="--headline2" md-font="--headline2" margin="10px 0">
						Ваш надежный партнер по ремонту техники
					</Text>
				</StackItem>
			</Stack>
		</Section>
		<Section padding="60px 0 60px 0" md-padding="30px 0 30px 0" border-color="--color-secondary">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				padding="0px 90px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				width="50%"
				lg-padding="0px 28px 0px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				align-self="center"
			>
				<Text
					font="--headline5"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					margin="0px 0px 32px 0px"
					color="--light"
				>
					«Ремонт Техники Эксперт» - это команда специалистов с большим объемом информации в сфере ремонтной техники.
				</Text>
				<Text
					color="--light"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 16px 0px"
					margin="0px 0px 0px 0px"
				>
					Мы работаем с разными видами техники, включая компьютеры, смартфоны, бытовую технику и электронику. Наши мастера постоянно совершенствуют свои знания и навыки, чтобы быть в курсе последних новинок и технологий.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					src="https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/remont-teh-lscyoz.jpeg?v=2024-06-13T08:27:07.208Z"
					width="100%"
					max-height="100%"
					object-fit="cover"
					lg-min-height="300px"
					md-max-height="277px"
					md-min-height="16px"
					height="300px"
					object-position="bottom"
					srcSet="https://smartuploads.quarkly.io/66696d2e3f24780021f7ccff/images/remont-teh-lscyoz.jpeg?v=2024-06-13T08%3A27%3A07.208Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66696d2e3f24780021f7ccff/images/remont-teh-lscyoz.jpeg?v=2024-06-13T08%3A27%3A07.208Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66696d2e3f24780021f7ccff/images/remont-teh-lscyoz.jpeg?v=2024-06-13T08%3A27%3A07.208Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66696d2e3f24780021f7ccff/images/remont-teh-lscyoz.jpeg?v=2024-06-13T08%3A27%3A07.208Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66696d2e3f24780021f7ccff/images/remont-teh-lscyoz.jpeg?v=2024-06-13T08%3A27%3A07.208Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66696d2e3f24780021f7ccff/images/remont-teh-lscyoz.jpeg?v=2024-06-13T08%3A27%3A07.208Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66696d2e3f24780021f7ccff/images/remont-teh-lscyoz.jpeg?v=2024-06-13T08%3A27%3A07.208Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				width="100%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					font="--headline2"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
					text-transform="uppercase"
				>
					Почему нас выбирают
				</Text>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="40px 0px 0px 0px"
					color="--light"
					font="--lead"
				>
					Профессионализм и опыт: У нас есть многолетний опыт работы и специализируется на ремонте техники любой сложности.{"\n"}
					<br />
					<br />
					{"\n"}Скорость обслуживания: Мы ценим ваше время и обеспечиваем быстрый и качественный ремонт.
					<br />
					<br />
					{"\n\n"}Гарантия качества Мы предоставляем гарантию на все виды работ и используем только качественные запчасти.{"\n"}
					<br />
					<br />
					{"\n"}Индивидуальный подход Каждый клиент для нас особенный, и мы всегда стараемся найти оптимальное решение для каждого случая.{"\n"}
					<br />
					<br />
					{"\n"}Доступные цены: Мы предлагаем конкурентоспособные цены без скрытых платежей.
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 0 0" />
		<Structure cells-number-total="3" cells-number-group="3">
			<Override slot="Content" grid-template-columns="9fr 3fr" md-grid-template-columns="repeat(6, 2fr)" sm-grid-template-columns="12fr">
				<Override
					slot="Cell 0th"
					grid-column="1 / auto"
					grid-row="auto / span 2"
					md-grid-column="1 / span 6"
					md-grid-row="span"
					sm-grid-column="auto"
					sm-grid-row="span"
				/>
				<Override slot="Cell 1st" md-grid-column="1 / span 3" sm-grid-column="auto" />
				<Override slot="Cell 2nd" md-grid-column="4 / span 3" sm-grid-column="auto" />
				<Override slot="cell-0">
					<Text
						margin="0px 0px 24px 0px"
						color="#ffffff"
						font="--headline2"
						sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
						text-transform="uppercase"
					>
						Наши услуги
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="--headline4"
						color="--light"
						text-align="left"
						lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
						md-margin="0px 0px 32px 0px"
						max-width="70%"
					>
						Мы предлагаем широкий спектр услуг по ремонту и обслуживанию техники.
					</Text>
				</Override>
				<Override slot="cell-1">
					<Text
						margin="32px 0px 16px 0px"
						font="--lead"
						color="--green"
						lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
						md-margin="12px 0px 16px 0px"
						sm-margin="24px 0px 16px 0px"
					>
						Установка программного обеспечения и обновление систем
					</Text>
				</Override>
				<Override slot="cell-2">
					<Text
						sm-margin="24px 0px 16px 0px"
						margin="32px 0px 16px 0px"
						font="--lead"
						color="--green"
						lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
						md-margin="12px 0px 16px 0px"
					>
						Обслуживание и настройка сетевых устройств
					</Text>
				</Override>
				{"        "}{"        "}{"        "}{"    "}
			</Override>
		</Structure>
		<Structure cells-number-total="3" cells-number-group="3">
			<Override slot="Content" grid-template-columns="repeat(3, 4fr)" sm-grid-template-columns="12fr">
				<Override slot="cell-0">
					<Text
						sm-margin="24px 0px 16px 0px"
						margin="32px 0px 16px 0px"
						font="--headline4"
						color="--green"
						lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
						md-margin="12px 0px 16px 0px"
					>
						Ремонт компьютеров и ноутбуков
					</Text>
				</Override>
				<Override slot="cell-1">
					<Text
						sm-margin="24px 0px 16px 0px"
						margin="32px 0px 16px 0px"
						font="--headline4"
						color="--green"
						lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
						md-margin="12px 0px 16px 0px"
					>
						Ремонт смартфонов и планшетов
					</Text>
				</Override>
				<Override slot="cell-2">
					<Text
						margin="32px 0px 16px 0px"
						font="--headline4"
						color="--green"
						lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
						md-margin="12px 0px 16px 0px"
						sm-margin="24px 0px 16px 0px"
					>
						Ремонт бытовой техники
					</Text>
				</Override>
			</Override>
		</Structure>
		<Section
			padding="140px 0 140px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/b50833725c16fa8b853ee1ceaa109dc1.jpg?v=2024-06-13T08:27:07.234Z) center center/cover no-repeat"
			min-height="40vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline3"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Доверьте ремонт вашей техники специалистам. "Ремонт Техники Эксперт" – это ваш надежный партнер в мире техники.
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Мы обеспечим вам качество, надежность и быстроту. Свяжитесь с нами сегодня!
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Контакты
				</Link>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1" />
			<Override slot="box2" />
			<Override slot="box1" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="box3" />
			<Override slot="box" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});